<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#E3F2FD">
        <v-card-text
          class="w-block__texte alternate"
          :class="{
            'pa-3': $voicer.isMobile === false,
            'pa-3': $voicer.isMobile === true,
            Subtitle2: $voicer.isMobile,
            Subtitle2: $voicer.isMobile === false,
          }"
        >
          <span class="texte_1 primary--text"
            >LYSSNA, c’est le nouveau podcast de IKEA France !</span
          >
          <div class="texte_2">
            Pas de pub, pas de Céline Dion, mais tu entendras toutes les
            histoires des collègues ! On y parle de notre culture, de nos
            valeurs, de ce qui s’est passé, se passe et se passera chez IKEA
            pour des années encore. A nous les studios !
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.texte_2 {
  font-size: 14px;
}

.texte_1 {
  font-size: 19px;
}
</style>
